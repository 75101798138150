
import { Component, Mixins } from 'vue-property-decorator'
import { Step } from '../mixins/StepMixin'
import Joi from 'joi'

@Component
export default class CompanyName extends Mixins(Step) {
  companyName = ''
  error = ''
  schema = {
    companyName: Joi
      .string()
      .min(3)
      .max(150)
  };

  beforeMount (): void {
    if (this.$store.getters.userData.company.name) {
      this.companyName = this.$store.getters.userData.company.name
      this.$store.dispatch('setNextStatus', true)
    }
  }

  get showFormErrors () {
    return this.$store.getters.formErrors
  }

  async validatePage (): Promise<any> {
    const result = await this.validate(this.schema, { companyName: this.companyName })
    this.$store.dispatch('setNextStatus', true)
    if (result && result.errors !== undefined) {
      this.error = result.errors.companyName
      this.$store.dispatch('setCurrentPageInvalid')
    } else {
      this.error = ''
      this.$store.dispatch('setCurrentPageValid')
      this.$store.dispatch('setUserData', {
        company: { name: this.companyName }
      })
    }
  }
}
